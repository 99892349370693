const islands = {
"type": "FeatureCollection",
"name": "Islands",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
{ "type": "Feature", "properties": { "Name": "Islands" }, "geometry": { "type": "Point", "coordinates": [ 29.334589533257141, -2.046545559663589, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Islands" }, "geometry": { "type": "Point", "coordinates": [ 29.333910540333449, -2.036385845848771, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Islands" }, "geometry": { "type": "Point", "coordinates": [ 29.330627361452159, -2.035154909031016, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Islands" }, "geometry": { "type": "Point", "coordinates": [ 29.308572737725139, -2.016982111310206, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Islands" }, "geometry": { "type": "Point", "coordinates": [ 29.30727970202955, -2.026301549339968, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Islands" }, "geometry": { "type": "Point", "coordinates": [ 29.300740169709812, -2.011796082080402, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island" }, "geometry": { "type": "Point", "coordinates": [ 29.17922563144424, -2.23471998330995, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island" }, "geometry": { "type": "Point", "coordinates": [ 29.17575539996546, -2.256988435837359, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island" }, "geometry": { "type": "Point", "coordinates": [ 29.163029145439609, -2.259540336409287, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island" }, "geometry": { "type": "Point", "coordinates": [ 29.20022758997208, -2.259082197664526, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island" }, "geometry": { "type": "Point", "coordinates": [ 29.158506830986671, -2.289936015008097, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island" }, "geometry": { "type": "Point", "coordinates": [ 29.137211936433939, -2.2712155566416, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Islands" }, "geometry": { "type": "Point", "coordinates": [ 29.153516289155871, -2.268106913889496, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island" }, "geometry": { "type": "Point", "coordinates": [ 29.261511083525399, -2.151939458686542, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island" }, "geometry": { "type": "Point", "coordinates": [ 29.242326920383672, -2.153411280874307, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island" }, "geometry": { "type": "Point", "coordinates": [ 28.932373862632101, -2.387049233370726, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island" }, "geometry": { "type": "Point", "coordinates": [ 28.905454113970311, -2.398923599063651, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island" }, "geometry": { "type": "Point", "coordinates": [ 28.888324452666261, -2.444288949519035, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island" }, "geometry": { "type": "Point", "coordinates": [ 28.89740881757697, -2.440260942824014, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Islands" }, "geometry": { "type": "Point", "coordinates": [ 28.893387364401871, -2.425684627996328, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null }, "geometry": { "type": "Point", "coordinates": [ 29.301518784901781, -2.023488415476109, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null }, "geometry": { "type": "Point", "coordinates": [ 29.323597954357822, -2.031120720967087, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null }, "geometry": { "type": "Point", "coordinates": [ 29.307827119032076, -2.016907499006847, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null }, "geometry": { "type": "Point", "coordinates": [ 29.334851710923608, -2.046891556292833, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null }, "geometry": { "type": "Point", "coordinates": [ 29.338901505673924, -2.041439909513563, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null }, "geometry": { "type": "Point", "coordinates": [ 29.352063338612449, -2.040076997818745, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null }, "geometry": { "type": "Point", "coordinates": [ 29.324513052210069, -2.053375121926751, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null }, "geometry": { "type": "Point", "coordinates": [ 29.302901166763679, -2.0767003820752, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null }, "geometry": { "type": "Point", "coordinates": [ 29.269724002078405, -2.087486854631328, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null }, "geometry": { "type": "Point", "coordinates": [ 29.149398369593094, -1.93565849182865, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null }, "geometry": { "type": "Point", "coordinates": [ 29.268309701668066, -1.913714341701444, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null }, "geometry": { "type": "Point", "coordinates": [ 29.259846708210162, -2.092509628469006, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null }, "geometry": { "type": "Point", "coordinates": [ 29.212016085949131, -2.15588866613284, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null }, "geometry": { "type": "Point", "coordinates": [ 29.1938033208984, -2.20101616054645, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null }, "geometry": { "type": "Point", "coordinates": [ 29.182144919993032, -2.203582124382081, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null }, "geometry": { "type": "Point", "coordinates": [ 29.185759347893423, -2.222366546659731, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null }, "geometry": { "type": "Point", "coordinates": [ 29.180916123089833, -2.228085673821425, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null }, "geometry": { "type": "Point", "coordinates": [ 29.006985100422405, -2.331983150592186, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null }, "geometry": { "type": "Point", "coordinates": [ 28.966629187364799, -2.354048161466016, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Ile Ngarwa" }, "geometry": { "type": "Point", "coordinates": [ 29.279297921459147, -1.821253515894975, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Ile Ntumba" }, "geometry": { "type": "Point", "coordinates": [ 29.269773402236709, -1.825430936606572, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null }, "geometry": { "type": "Point", "coordinates": [ 29.28826152, -1.82937987, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": null }, "geometry": { "type": "Point", "coordinates": [ 29.283359388690968, -1.848093798663938, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island 1" }, "geometry": { "type": "Point", "coordinates": [ 29.76534863918603, -1.452148366670035, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island 2" }, "geometry": { "type": "Point", "coordinates": [ 29.768851370788479, -1.468478339359916, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island 3" }, "geometry": { "type": "Point", "coordinates": [ 29.762997699215443, -1.43483858261208, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island 4" }, "geometry": { "type": "Point", "coordinates": [ 29.787487547132159, -1.439519172041367, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island 1" }, "geometry": { "type": "Point", "coordinates": [ 29.73752813127286, -1.533585397210752, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "island 2" }, "geometry": { "type": "Point", "coordinates": [ 29.73129298330057, -1.513560601414094, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "island 3" }, "geometry": { "type": "Point", "coordinates": [ 29.733272159035749, -1.50729731663026, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island 4" }, "geometry": { "type": "Point", "coordinates": [ 29.7325763995347, -1.503536952319872, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island 5" }, "geometry": { "type": "Point", "coordinates": [ 29.73212279758366, -1.500603397825092, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island 6" }, "geometry": { "type": "Point", "coordinates": [ 29.733803091843949, -1.500655369079285, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island 7" }, "geometry": { "type": "Point", "coordinates": [ 29.731632144586161, -1.496891230231724, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island 8" }, "geometry": { "type": "Point", "coordinates": [ 29.73851895884291, -1.505573398319393, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island 9" }, "geometry": { "type": "Point", "coordinates": [ 29.74488323574796, -1.51195216321039, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island 10" }, "geometry": { "type": "Point", "coordinates": [ 29.761554754763889, -1.52299666534222, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island 11" }, "geometry": { "type": "Point", "coordinates": [ 29.761177652398921, -1.533842327571366, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island 12" }, "geometry": { "type": "Point", "coordinates": [ 29.757394554355411, -1.541916011665952, 0.0 ] } },
{ "type": "Feature", "properties": { "Name": "Island 13" }, "geometry": { "type": "Point", "coordinates": [ 29.7164923023245, -1.51289986073256, 0.0 ] } }
]
}

export default islands;