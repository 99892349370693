const hotspring = {
"type": "FeatureCollection",
"name": "hot spring",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
{ "type": "Feature", "properties": { "Name": "Nyamnyumba host springs" }, "geometry": { "type": "Point", "coordinates": [ 29.273371554746941, -1.740206595567273, 0.0 ] } }
]
}

export default hotspring;
