import { useState, useContext, useCallback, useEffect } from 'react';
import {
    Navbar,
    Group,
    ActionIcon,
    NavLink,
    useMantineColorScheme,
    ScrollArea,
    Space,
    Divider,
    Text,
    useMantineTheme
  } from '@mantine/core';
  import { useLocalStorage } from '@mantine/hooks';
  import {
    IconHelp,
    IconSettings,
    IconActivity,
    IconAlertTriangle,
    IconHome,
    IconShieldCheckered,
    IconUser,
    IconChartAreaLine,
    IconAnalyze,
    IconTable,
    IconUsers,
    IconShieldLockFilled,
    IconDatabase,
    IconShare,
    IconTrash,
    IconMap,
    IconLineHeight,
    IconLayout,
    IconChartHistogram,
  } from '@tabler/icons-react';
import useStyles from "./navbar.style";
import { SidebarContext } from '../context/sidebar/sidebar.context';
import { Link, useLocation, } from "react-router-dom";
import { ArrowUpRight, Book, Logout } from 'tabler-icons-react';
import { AuthContext } from '../../../../App';

const SIDEBAR_VARIANT = 'e-mita-sidebar-variant';

  export default function DrawerNavbar({img}) {
    const { classes } = useStyles();
    const { state, dispatch } = useContext(SidebarContext);
    const { state: authState, dispatch: authDispatch} = useContext(AuthContext);
    const [, setOpened] = useState(false);

    const location = useLocation();

    const [sidebar, ] = useLocalStorage({
      key: SIDEBAR_VARIANT,
      defaultValue: 'default',
      getInitialValueInEffect: true,
    });

    const links = [
      { icon: IconHome, label: 'Home', href: `/dashboard/` },
      { icon: IconLineHeight, label: 'Bathymetry', href: `/dashboard/bathymetry`, links: [
        {label: "Muhazi", href: "/dashboard/bathymetry/muhazi"},
        {label: "Mugesera", href: "/dashboard/bathymetry/mugesera"},
        {label: "Ruhondo", href: "/dashboard/bathymetry/ruhondo"},
        {label: "Burera", href: "/dashboard/bathymetry/burera"},
        {label: "Kivu", href: "/dashboard/bathymetry/kivu"},
      ] },
      { icon: IconLayout, label: 'Zoning', href: `/dashboard/zoning`, links: [
        {label: "Muhazi", href: "/dashboard/zoning/muhazi"},
        {label: "Mugesera", href: "/dashboard/zoning/mugesera"},
        {label: "Ruhondo", href: "/dashboard/zoning/ruhondo"},
        {label: "Burera", href: "/dashboard/zoning/burera"},
        {label: "Kivu", href: "/dashboard/zoning/kivu"},
      ]  },
      { icon: IconChartAreaLine, label: 'Water Use', href: `/dashboard/water-use`, },
      { icon: IconChartHistogram, label: 'Sampling Points', href: `/dashboard/sampling_points`, },
      { icon: IconUser, label: 'Co-Admins', href: `/dashboard/admins`, },
      { icon: IconUsers, label: 'Enumerators', href: `/dashboard/enumerators`},
      { icon: IconShare, label: 'Data Requests', href: `/dashboard/data-requests`, },
      { icon: IconAnalyze, label: 'Site Analytics', href: `#`, },
      { icon: IconSettings, label: 'Settings', href: `/dashboard/settings`, },
    ];


    useEffect(() => {
      if(window.location.href.includes("/app/configure")){
        setOpened(true);
      } else {
        setOpened(false);
      }
    }, [window.location.href])

    const itemLinksLight = links.map((link, idx) => {
        return(
          <NavLink
          component={Link}
          to={link.href}
          description={link.description?.label}
          label={link.label}
          icon={<link.icon strokeWidth={1} size={18} />}
          key={link.label}
          mb={10}
          variant='subtle'
          active={location.pathname == link.href}
          />
        )
      })

  
    return (
      <Navbar hiddenBreakpoint="sm" hidden={state.opened} p="md" className={classes.navbar}>

        <Navbar.Section mt={50} grow component={ScrollArea} sx={(theme) => ({height: '100%' })} className={classes.section}>
        <div className={classes.mainLinks}>{itemLinksLight}</div>
        </Navbar.Section>

        <Navbar.Section style={{bottom: 0}} className={classes.lastSection}>
        <Divider mb={10} mr={10} ml={10} />
        <NavLink
          component={Link}
          to={'#'}
          label="Trash"
          icon={<IconTrash strokeWidth={1} size={18} />}
          />
          <NavLink
          component={"a"}
          href={'#'}
          target='_blank'
          label="Questionnaire"
          icon={<ArrowUpRight strokeWidth={1} size={18} />}
          />
          <NavLink
          component={Link}
          to={'#'}
          label="Sign Out"
          onClick={() => {authDispatch({type:"SIGN_OUT"})}}
          icon={<Logout color='red' strokeWidth={1} size={18} />}
          />
        </Navbar.Section>

      </Navbar>
    );
  }