import { GeoJSON, useMapEvents } from "react-leaflet"
import { Box, Container, Group, SimpleGrid, Tabs, Text, Title } from "@mantine/core";
import { LayersControl, MapContainer, TileLayer } from "react-leaflet";
import { useViewportSize } from "@mantine/hooks";
import fish from "../../../assets/zones/fish";
import { useCallback, useContext, useEffect, useState } from "react";
import fishing_areas from "../../../assets/zones/fishing_areas";
import tourism from "../../../assets/zones/tourism";
import restricted_areas from "../../../assets/zones/restricted_areas";
import { AuthContext } from "../../../App";
import L from "leaflet";
import { FishingAsWaterUse, GasExtractionAsWaterUse, TourismAsWaterUse } from "../../../assets/zones/water_uses/Fishing";
import toast from "react-hot-toast";
import axios from "../../../utils/axios";

export function MuhaziZoning(){
    const { state, dispatch } = useContext(AuthContext);
    const [zoom, setZoom] = useState(10);
    const [center, setCenter] = useState([-1.857667065511206, 30.323943276828512]);

    const [water_use, setWaterUse] = useState([]);

    const ZoomComponent = () => {
        useMapEvents({
            zoomend: useCallback((e) => {
                if (e?.target) {
                  //setZoom(e.target.getZoom());
                }
            }, []),

            moveend: useCallback((e) => {
                if (e?.target) {
                  const el = e.target.getCenter();
                  //setCenter([el.lat, el.lng]);
                }
              }, [])
        });
    
        return null
    }

    const fetchData = () => {
        const config = {
            headers: {
                'Authorization': `Bearer ${state.userToken}`
            }
        }

        axios.get("/water-use/all", config).then(function(res){
            if(res.status === 200){
                let arr = res.data.data;

                setWaterUse(arr);
            }
        }).catch(function(error){
            toast.error("Could not load water use dataset. Please check your server connection.", {
                duration: 10000
            });
        })
    }

    useEffect(() => {
        fetchData();
    }, [])

    function MuhaziFishingZone(){
        const [data, setData] = useState(null);
        const [data2, setData2] = useState(null);
    
        const MuhaziFishingZoneLayer = () => {
            return (
                <GeoJSON data={data} style={(f) => {
                    return {
                        color: "red",
                        fillColor: "red",
                        fillOpacity: 1
                    }
                }} />
            )
        }
    
        const { height, width } = useViewportSize();
    
        useEffect(() => {
            let filteredData = fish.features.filter((item) => {
                if(item.properties.name === "Lake Muhazi"){
                    return item
                }
            });
        
            setData(filteredData);
        }, [])
    
        useEffect(() => {
            let filteredData = fishing_areas.features.filter((item) => {
                if(item.properties.name === "Lake Muhazi"){
                    return item
                }
            });
        
            setData2(filteredData);
        }, [])
    
        return (
            <div style={{height: `45vh`}}>
            <MapContainer center={center} style={{background: "transparent", zIndex: 1, height: '100%', width: '100%', padding: 0}} zoom={zoom} minZoom={10}>
            <LayersControl position="topright">
            <LayersControl.BaseLayer checked name='CartoDB'>
            <TileLayer
            attribution='&copy; HydroIntel Africa Limited, &copy; Emita, &copy; Hydrosys Limited, contributors: <a href="http://cartodb.com/attributions#basemaps">CartoDB</a>'
            url= "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}.png"
            />
            </LayersControl.BaseLayer>
    
            <LayersControl.BaseLayer name='Satellite'>
            <TileLayer
            attribution='&copy; Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            />
            </LayersControl.BaseLayer>
            </LayersControl>
    
            <MuhaziFishingZoneLayer />
            <ZoomComponent />

            <TourismAsWaterUse datax={water_use} />
            <GasExtractionAsWaterUse datax={water_use} />
            <FishingAsWaterUse datax={water_use} />
    
            <Container sx={(theme) => ({marginTop: -10, marginLeft: -10, border: "none", left: 0, padding: 0, [theme.fn.smallerThan('md')]: {
                display: "none"
              }})} className='leaflet-bottom leaflet-left'>
            <Container className="leaflet-control leaflet-bar" sx={(theme) => ({
            backgroundColor: "#ffff",
            width: width * 0.1,
            border: "none",
            marginBottom: 30,
            [theme.fn.smallerThan('md')]: {
                display: "none"
              }
            })} p="md" >
                <Title mb="md" order={5}>Legend</Title>
                <Group position="apart" noWrap>
                    <Group position="left">
                        <Box style={{height: 30, width: 30, backgroundColor:"red"}} />
                    </Group>
                    <Group position="right">
                        <Text>Fish Farming</Text>
                    </Group>
                </Group>
    
            </Container>
            </Container>
        </MapContainer>
        </div>
        )
    }

    function MuhaziFishingAreasZones(){
        const [data2, setData2] = useState(null)
    
        const MuhaziFishingAreasZoneLayer = () => {
            return (
                <GeoJSON data={data2} style={(f) => {
                    return {
                        color: "blue",
                        fillColor: "blue",
                        fillOpacity: 1
                    }
                }} />
            )
        }
    
        const { height, width } = useViewportSize();
    
        useEffect(() => {
            let filteredData = fishing_areas.features.filter((item) => {
                if(item.properties.name === "Lake Muhazi"){
                    return item
                }
            });
        
            setData2(filteredData);
        }, [])
    
        return (
            <div style={{height: `45vh`}}>
            <MapContainer center={center} style={{background: "transparent", zIndex: 1, height: '100%', width: '100%', padding: 0}} zoom={zoom} minZoom={10}>
            <LayersControl position="topright">
            <LayersControl.BaseLayer checked name='CartoDB'>
            <TileLayer
            attribution='&copy; HydroIntel Africa Limited, &copy; Emita, &copy; Hydrosys Limited, contributors: <a href="http://cartodb.com/attributions#basemaps">CartoDB</a>'
            url= "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}.png"
            />
            </LayersControl.BaseLayer>
    
            <LayersControl.BaseLayer name='Satellite'>
            <TileLayer
            attribution='&copy; Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            />
            </LayersControl.BaseLayer>
            </LayersControl>
    
            <MuhaziFishingAreasZoneLayer />
            <ZoomComponent />

            <TourismAsWaterUse datax={water_use} />
            <GasExtractionAsWaterUse datax={water_use} />
            <FishingAsWaterUse datax={water_use} />
    
            <Container sx={(theme) => ({marginTop: -10, marginLeft: -10, border: "none", left: 0, padding: 0, [theme.fn.smallerThan('md')]: {
                display: "none"
              }})} className='leaflet-bottom leaflet-left'>
            <Container className="leaflet-control leaflet-bar" sx={(theme) => ({
            backgroundColor: "#ffff",
            width: width * 0.1,
            border: "none",
            marginBottom: 30,
            [theme.fn.smallerThan('md')]: {
                display: "none"
              }
            })} p="md" >
                <Title mb="md" order={5}>Legend</Title>
                <Group position="apart" noWrap>
                    <Group position="left">
                        <Box style={{height: 30, width: 30, backgroundColor:"blue"}} />
                    </Group>
                    <Group position="right">
                        <Text>Fishing Areas</Text>
                    </Group>
                </Group>
    
            </Container>
            </Container>
        </MapContainer>
        </div>
        )
    }

    function MuhaziTourismZone(){
        const [data, setData] = useState(null)
    
        const MuhaziTourismZoneLayer = () => {
            return (
                <GeoJSON data={data} style={(f) => {
                    return {
                        color: "purple",
                        fillColor: "purple",
                        fillOpacity: 1
                    }
                }} />
            )
        }
    
        const { height, width } = useViewportSize();
    
        useEffect(() => {
            let filteredData = tourism.features.filter((item) => {
                if(item.properties.Name === "Lake Muhazi"){
                    return item
                }
            });
        
            setData(filteredData);
        }, [])
    
        return (
            <div style={{height: `45vh`}}>
            <MapContainer center={center} style={{background: "transparent", zIndex: 1, height: '100%', width: '100%', padding: 0}} zoom={zoom} minZoom={10}>
            <LayersControl position="topright">
            <LayersControl.BaseLayer checked name='CartoDB'>
            <TileLayer
            attribution='&copy; HydroIntel Africa Limited, &copy; Emita, &copy; Hydrosys Limited, contributors: <a href="http://cartodb.com/attributions#basemaps">CartoDB</a>'
            url= "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}.png"
            />
            </LayersControl.BaseLayer>
    
            <LayersControl.BaseLayer name='Satellite'>
            <TileLayer
            attribution='&copy; Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            />
            </LayersControl.BaseLayer>
            </LayersControl>
    
            <MuhaziTourismZoneLayer />
            <ZoomComponent />

            <TourismAsWaterUse datax={water_use} />
            <GasExtractionAsWaterUse datax={water_use} />
            <FishingAsWaterUse datax={water_use} />
    
            <Container sx={(theme) => ({marginTop: -10, marginLeft: -10, border: "none", left: 0, padding: 0, [theme.fn.smallerThan('md')]: {
                display: "none"
              }})} className='leaflet-bottom leaflet-left'>
            <Container className="leaflet-control leaflet-bar" sx={(theme) => ({
            backgroundColor: "#ffff",
            width: width * 0.1,
            border: "none",
            marginBottom: 30,
            [theme.fn.smallerThan('md')]: {
                display: "none"
              }
            })} p="md" >
                <Title mb="md" order={5}>Legend</Title>
                <Group position="apart" noWrap>
                    <Group position="left">
                        <Box style={{height: 30, width: 30, backgroundColor:"purple"}} />
                    </Group>
                    <Group position="right">
                        <Text>Tourism Zone</Text>
                    </Group>
                </Group>
    
            </Container>
            </Container>
        </MapContainer>
        </div>
        )
    }

    function MuhaziRestrictedAreaZone(){
        const [data, setData] = useState(null)
    
        const MuhaziRestrictedAreaLayer = () => {
            return (
                <GeoJSON data={data} style={(f) => {
                    return {
                        color: "green",
                        fillColor: "green",
                        fillOpacity: 1
                    }
                }} />
            )
        }
    
        const { height, width } = useViewportSize();
    
        useEffect(() => {
            let filteredData = restricted_areas.features.filter((item) => {
                if(item.properties.name === "Lake Muhazi"){
                    return item
                }
            });
        
            setData(filteredData);
        }, [])
    
        return (
            <div style={{height: `45vh`}}>
            <MapContainer center={center} style={{background: "transparent", zIndex: 1, height: '100%', width: '100%', padding: 0}} zoom={zoom} minZoom={10}>
            <LayersControl position="topright">
            <LayersControl.BaseLayer checked name='CartoDB'>
            <TileLayer
            attribution='&copy; HydroIntel Africa Limited, &copy; Emita, &copy; Hydrosys Limited, contributors: <a href="http://cartodb.com/attributions#basemaps">CartoDB</a>'
            url= "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}.png"
            />
            </LayersControl.BaseLayer>
    
            <LayersControl.BaseLayer name='Satellite'>
            <TileLayer
            attribution='&copy; Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            />
            </LayersControl.BaseLayer>
            </LayersControl>
    
            <MuhaziRestrictedAreaLayer />
            <ZoomComponent />

            <TourismAsWaterUse datax={water_use} />
            <GasExtractionAsWaterUse datax={water_use} />
            <FishingAsWaterUse datax={water_use} />
    
            <Container sx={(theme) => ({marginTop: -10, marginLeft: -10, border: "none", left: 0, padding: 0, [theme.fn.smallerThan('md')]: {
                display: "none"
              }})} className='leaflet-bottom leaflet-left'>
            <Container className="leaflet-control leaflet-bar" sx={(theme) => ({
            backgroundColor: "#ffff",
            width: width * 0.1,
            border: "none",
            marginBottom: 30,
            [theme.fn.smallerThan('md')]: {
                display: "none"
              }
            })} p="md" >
                <Title mb="md" order={5}>Legend</Title>
                <Group position="apart" noWrap>
                    <Group position="left">
                        <Box style={{height: 30, width: 30, backgroundColor:"green"}} />
                    </Group>
                    <Group position="right">
                        <Text>Restricted Area</Text>
                    </Group>
                </Group>
    
            </Container>
            </Container>
        </MapContainer>
        </div>
        )
    }
    return (
        <SimpleGrid cols={2} spacing={"xs"} breakpoints={[{maxWidth: 980, cols: 1}]}>
            <MuhaziFishingZone />
            <MuhaziFishingAreasZones />
            <MuhaziTourismZone />
            <MuhaziRestrictedAreaZone />
        </SimpleGrid>
    )
}