import { Toaster } from 'react-hot-toast';
import { FooterPage } from './footer/footer';
import { Outlet } from "react-router-dom";
import { useColorScheme } from '@mantine/hooks';

export function Layout({ noFooter=false}) {
  const theme = useColorScheme();
  return (
  <>
    <main style={{ paddingTop:  0}}><Outlet /></main>
    {!noFooter && <FooterPage />}
    <Toaster />
    </>
  );
}