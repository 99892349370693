import { useState, useContext } from 'react';
import {Navbar,NavLink,ScrollArea,Divider,useMantineTheme} from '@mantine/core';
import {IconUser,IconHelp,IconHome,IconUsers, IconGitFork, IconAnalyze, IconActivity, IconSettings, IconRefreshAlert, IconTool, IconAlertTriangle, IconUserPlus, IconReportAnalytics, IconTable, IconBrandAndroid, IconShieldLockFilled, IconHelpCircleFilled, IconChecklist, IconShare, IconTrash, IconChartAreaLine, IconDatabase, IconMap, IconLineHeight, IconLayout, IconChartHistogram,} from '@tabler/icons-react';
import useStyles from "./navbar.style";
import { SidebarContext } from '../context/sidebar/sidebar.context';
import { Link } from 'react-router-dom';
import { ArrowUpRight, Download, Logout} from 'tabler-icons-react';
import { AuthContext } from '../../../../App';
import { HEADER_HEIGHT } from '../header/header.style';
import { useViewportSize } from '@mantine/hooks';


export function NavbarSmall() {
    const { classes } = useStyles();
    const { state, dispatch } = useContext(SidebarContext);
    const { state: authState, dispatch: authDispatch} = useContext(AuthContext);
    const [opened, setOpened] = useState(false);

    const links = [
      { icon: IconHome, label: 'Home', href: `/dashboard/` },
      { icon: IconLineHeight, label: 'Bathymetry', href: `/dashboard/bathymetry`, links: [
        {label: "Muhazi", href: "/dashboard/bathymetry/muhazi"},
        {label: "Mugesera", href: "/dashboard/bathymetry/mugesera"},
        {label: "Ruhondo", href: "/dashboard/bathymetry/ruhondo"},
        {label: "Burera", href: "/dashboard/bathymetry/burera"},
        {label: "Kivu", href: "/dashboard/bathymetry/kivu"},
      ] },
      { icon: IconLayout, label: 'Zoning', href: `/dashboard/zoning`, links: [
        {label: "Muhazi", href: "/dashboard/zoning/muhazi"},
        {label: "Mugesera", href: "/dashboard/zoning/mugesera"},
        {label: "Ruhondo", href: "/dashboard/zoning/ruhondo"},
        {label: "Burera", href: "/dashboard/zoning/burera"},
        {label: "Kivu", href: "/dashboard/zoning/kivu"},
      ]  },
      { icon: IconChartAreaLine, label: 'Water Use', href: `/dashboard/water-use`, },
      { icon: IconChartHistogram, label: 'Sampling Points', href: `/dashboard/sampling_points`, },
      { icon: IconUser, label: 'Co-Admins', href: `/dashboard/admins`, },
      { icon: IconUsers, label: 'Enumerators', href: `/dashboard/enumerators`},
      { icon: IconShare, label: 'Data Requests', href: `/dashboard/data-requests`, },
      { icon: IconSettings, label: 'Settings', href: `/dashboard/settings`, },
    ];

    const links2 = [
      { icon: IconHome, label: 'Home', href: `/dashboard/` },
      { icon: IconLineHeight, label: 'Bathymetry', href: `/dashboard/bathymetry`, links: [
        {label: "Muhazi", href: "/dashboard/bathymetry/muhazi"},
        {label: "Mugesera", href: "/dashboard/bathymetry/mugesera"},
        {label: "Ruhondo", href: "/dashboard/bathymetry/ruhondo"},
        {label: "Burera", href: "/dashboard/bathymetry/burera"},
        {label: "Kivu", href: "/dashboard/bathymetry/kivu"},
      ] },
      { icon: IconLayout, label: 'Zoning', href: `/dashboard/zoning`, links: [
        {label: "Muhazi", href: "/dashboard/zoning/muhazi"},
        {label: "Mugesera", href: "/dashboard/zoning/mugesera"},
        {label: "Ruhondo", href: "/dashboard/zoning/ruhondo"},
        {label: "Burera", href: "/dashboard/zoning/burera"},
        {label: "Kivu", href: "/dashboard/zoning/kivu"},
      ]  },
      { icon: IconChartAreaLine, label: 'Water Use', href: `/dashboard/water-use`, },
      { icon: IconChartHistogram, label: 'Sampling Points', href: `/dashboard/sampling_points`, },
      { icon: IconSettings, label: 'Settings', href: `/dashboard/settings`, },
    ];

    const theme2 = useMantineTheme();

    const [sidebar,] = useState('!default');

    const itemLinksLight = links.map((link, idx) => {
      const hasLinks = Array.isArray(link.links);
      return(
        hasLinks ? (
          <NavLink variant='subtle' pb="md" active={window.location.pathname === link.href} component={Link} to={link.href} key={idx} label={link.label} icon={<link.icon strokeWidth={1} size={18}/>}  styles={{label: {color: sidebar === "default" ?  theme2.colors.gray[0] : null}, icon: {color: sidebar === "default" ?  theme2.colors.gray[0] : null}, root: {"&: hover": {backgroundColor: sidebar === "default" ? "transparent" : null}}}}
          >
            {link.links.map((itm) => {
              return (
                <NavLink
                component={Link}
                active={window.location.pathname === link.href}
                to={itm.href}
                key={itm.label}
                label={itm.label}
                />
              )
            })}
          </NavLink>
        ) : (
          <NavLink
          component={Link}
          styles={{label: {color: sidebar === "default" ?  theme2.colors.gray[0] : null}, icon: {color: sidebar === "default" ?  theme2.colors.gray[0] : null}, root: {"&: hover": {backgroundColor: sidebar === "default" ? "transparent" : null}}}}
          active={window.location.pathname === link.href}
          to={link.href}
          variant='subtle'
          description={link.description?.label}
          label={link.label}
          icon={<link.icon strokeWidth={1} size={18} />}
          key={link.label}
          pb="md"
          />
        )
      )
    })

    const itemLinksLight2 = links2.map((link, idx) => {
      const hasLinks = Array.isArray(link.links);
      return(
        hasLinks ? (
          <NavLink variant='subtle' pb="md" active={window.location.pathname === link.href} component={Link} to={link.href} key={idx} label={link.label} icon={<link.icon strokeWidth={1} size={18}/>}  styles={{label: {color: sidebar === "default" ?  theme2.colors.gray[0] : null}, icon: {color: sidebar === "default" ?  theme2.colors.gray[0] : null}, root: {"&: hover": {backgroundColor: sidebar === "default" ? "transparent" : null}}}}
          >
            {link.links.map((itm) => {
              return (
                <NavLink
                component={Link}
                active={window.location.pathname === link.href}
                to={itm.href}
                key={itm.label}
                label={itm.label}
                />
              )
            })}
          </NavLink>
        ) : (
          <NavLink
          component={Link}
          styles={{label: {color: sidebar === "default" ?  theme2.colors.gray[0] : null}, icon: {color: sidebar === "default" ?  theme2.colors.gray[0] : null}, root: {"&: hover": {backgroundColor: sidebar === "default" ? "transparent" : null}}}}
          active={window.location.pathname === link.href}
          to={link.href}
          variant='subtle'
          description={link.description?.label}
          label={link.label}
          icon={<link.icon strokeWidth={1} size={18} />}
          key={link.label}
          pb="md"
          />
        )
      )
    })

    const { height, width } = useViewportSize();

    return (
      <Navbar zIndex={1} hiddenBreakpoint="sm" height={height - HEADER_HEIGHT} hidden={state.opened} width={{ sm: state.width }} sx={(theme) => ({[theme.fn.smallerThan('sm')]: { display: 'none' }, height: '100%' })} p={0} pt={"lg"} className={classes.navbar}> 
        <Navbar.Section mt={-15} grow component={ScrollArea} sx={(theme) => ({ [theme.fn.smallerThan('sm')]: { display: 'none' }, height: '100%' })} className={classes.section}>
        <div className={classes.mainLinks}>{authState.userData.role === "super" ? itemLinksLight : itemLinksLight2}</div>
        </Navbar.Section>
        <Navbar.Section style={{bottom: 10, marginBottom: 70}} className={classes.lastSection}>
        <Divider mb={10} mr={10} ml={10} />
        {authState.userData.role === "super" ? (
        <NavLink
        component={"a"}
        styles={{label: {color: sidebar === "default" ?  theme2.colors.gray[0] : null}, icon: {color: sidebar === "default" ?  theme2.colors.gray[0] : null}, root: {"&: hover": {backgroundColor: sidebar === "default" ? "transparent" : null}}}}
        href={'https://rwanda-wuda.onrender.com/metrics'}
        label="Server Metrics"
        target='_blank'
        icon={<IconAnalyze strokeWidth={1} size={18} />}
        />
        ) : null}
          <NavLink
          component={"a"}
          styles={{label: {color: sidebar === "default" ?  theme2.colors.gray[0] : null}, icon: {color: sidebar === "default" ?  theme2.colors.gray[0] : null}, root: {"&: hover": {backgroundColor: sidebar === "default" ? "transparent" : null}}}}
          href={'#'}
          target='_blank'
          label="Questionnaire"
          icon={<ArrowUpRight strokeWidth={1} size={18} />}
          />

          <NavLink
          component={"a"}
          styles={{label: {color: sidebar === "default" ?  theme2.colors.gray[0] : null}, icon: {color: sidebar === "default" ?  theme2.colors.gray[0] : null}, root: {"&: hover": {backgroundColor: sidebar === "default" ? "transparent" : null}}}}
          href={'#'}
          target='_blank'
          label="WUDA Mobile App"
          icon={<Download strokeWidth={1} size={18} />}
          />
        </Navbar.Section>
  
      </Navbar>
    );
  }