import { Button, Center, Checkbox, Drawer, Group, Menu, Pagination, Paper, PasswordInput, Select, Stack, Table, Text, TextInput, Title } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import axios from "../../../utils/axios";
import { AuthContext } from "../../../App";
import toast from "react-hot-toast";
import { useViewportSize } from "@mantine/hooks";

export default function Admins(){
    const { state, dispatch } = useContext(AuthContext);
    const [r, setR] = useState(false);
    const [admins, setAdmins] = useState([]);
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("ordinary");
    const [opened, setOpened] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchAdmins = () => {
        axios.get("/admin/all", {headers: {'Authorization': `Bearer ${state.userToken}`}}).then(function(res){
          if(res.status === 200){
            setAdmins(res.data.data);
          }
        }).catch(function(err){
          console.log(err);
        })
    }

    useEffect(() => {
        fetchAdmins();
    }, [r]);

    const createAdmin = () => {
        const config = {
            headers: {
                'Authorization': `Bearer ${state.userToken}`
            }
        }

        if(name.trim().length < 2){
            toast.error("Invalid name");
            return false;
        }

        if(username.trim().length < 2){
            toast.error("Invalid username");
            return false;
        }

        if(password.length < 8){
            toast.error("Password should have atleast 8 characters");
            return false;
        }

        setLoading(true);

        const body = {
            name: name,
            username: username,
            password: password,
            role: role
        }
        axios.post("/admin/create", body, config)
        .then(function(res){
            if(res.status === 200){
                toast.success("The admin was added successfully");
                setR(!r);
                setLoading(false);
                setOpened(false);
            }
        }).catch(function(err){
            setLoading(false);
            toast.error("An issue occured while adding the admin");
        })
    }

    const { height, width } = useViewportSize();
    return (
        <Paper p="md" withBorder>
            <Group mb={"md"} position="apart">
            <Title order={3} fw={500}>Admins</Title>

            <Group position="right">
            <Button>Export all as CSV</Button>
            <Button onClick={() => {setOpened(true)}}>Add New Admin</Button>
            </Group>
            </Group>

            <TextInput mb={20} radius={"md"} placeholder="search..." />

            <Table fontSize={"xs"}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Username</th>
                        <th>Role</th>
                        <th>Created At</th>
                        <th>Last Modified</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {admins.length === 0 ? (
                                            <tr>
                                            <td colSpan={5}>
                                                <Center>
                                                    <Text size={"xs"}>No data was found</Text>
                                                </Center>
                                            </td>
                                        </tr>
                    ) : (
                        admins.map((item, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{item.name}</td>
                                    <td>{item.username}</td>
                                    <td>{item.role}</td>
                                    <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                                    <td>{new Date(item.updatedAt).toLocaleDateString()}</td>
                                    <td>
                                        <Menu>
                                            <Menu.Target>
                                                <Button variant="light">Actions</Button>
                                            </Menu.Target>

                                            <Menu.Dropdown>
                                                <Menu.Item>Update</Menu.Item>
                                                <Menu.Item>Delete</Menu.Item>
                                            </Menu.Dropdown>
                                        </Menu>
                                    </td>
                                </tr>
                            )
                        })
                    )}
                </tbody>
            </Table>

            <Group mt={30} position="right">
            <Pagination size="sm" page={1} total={1} siblings={0} spacing="xs" withControls radius="xs" />
            </Group>

    <Drawer padding={"md"} position="right" withOverlay={false} opened={opened} onClose={() => {setOpened(false)}} withCloseButton={false}>
        <Stack h={height * 0.9} justify="space-between">
        <div>
        <Title order={5} mb={10}>Add New Admin</Title>
        <Text mb={20}>Add new admin into the system.</Text>
        <Select label="Role" description="Role of the admin. Restricts which pages an admin can access" data={[{label: "Super", value: "super"}, {label: "Ordinary", value: "ordinary"}]} value={role} onChange={(val) => {setRole(val)}} mb={"md"} />
        <TextInput value={name} onChange={(e) => {setName(e.currentTarget.value)}} label="Name" description="Name of the admin" mb={"md"} />
        <TextInput value={username} onChange={(e) => {setUsername(e.currentTarget.value)}} label="Username" description="Email address of the admin." mb={"md"} />
        <PasswordInput value={password} onChange={(e) => {setPassword(e.currentTarget.value)}} label="Password" description="An easy-to-remember password value." />
            
        </div>

        <Group>
            <Button onClick={() => {setOpened(false)}} variant="default">Discard</Button>
            <Button loading={loading} onClick={() => {createAdmin()}} radius={28}>Add Admin</Button>
        </Group>
        </Stack>
    </Drawer>
        </Paper>
    )
}